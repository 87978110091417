import React from "react";
import LayoutHeaderFooter from "../../components/LayoutHeaderFooter/LayoutHeaderFooter";
import FirstComponent from "../../components/FirstComponent/FirstComponent";
import HagyomanyosModszer from "../../components/HagyomanyosModszer/HagyomanyosModszer";
import PaymentMethods from "../../components/PaymentMethods/PaymentMethods";
import hagyomanyos from "../../assets/images/HagyomanyosModszer/adougyi_nyomtato2 1.png";
import mobil_afr from "../../assets/images/HagyomanyosModszer/mobil_afr.png";
import softPOS_image from "../../assets/images/HagyomanyosModszer/softPOS.png";
import FullWidthImageBox from "../../components/FullWidthImageBox/FullWidthImageBox";
import velemeny from "../../assets/images/FullWidthImage/velemeny.png";
import ImageNextToDesc from "../../components/ImageNextToDesc/ImageNextToDesc";
import erintesmentes_image from "../../assets/images/ImageNextToDesc/erintesmentes.svg";
import ajanlas_image from "../../assets/images/ImageNextToDesc/ajanlas.svg";
import QuoteComponent from "../../components/QuoteComponent/QuoteComponent";
import TitleIcons from "../../components/TitleIcons/TitleIcons";
import kutyuk from "../../assets/images/TitleIcons/kutyuk.svg";
import vallalkozoknak from "../../assets/images/TitleIcons/vallalkozoknak.svg";
import keszpenzes from "../../assets/images/TitleIcons/keszpenzes.svg";
import HowToPay from "../../components/HowToPay/HowToPay";
import plusz from "../../assets/images/HowToPay/plusz.svg";
import qr from "../../assets/images/HowToPay/qr.svg";
import pipa from "../../assets/images/HowToPay/pipa.svg";
import TitleDescription from "../../components/TitleDescription/TitleDescription";
import CardsArguments from "../../components/CardsArguments/CardsArguments";
import TorvenyiEloiras from "../../components/TorvenyiEloiras/TorvenyiEloiras";
import TitleDescSections from "../../components/TitleDescSections/TitleDescSections";
import RegisterAndDownload from "../../components/RegisterAndDownload/RegisterAndDownload";
import bkt from "../../assets/images/TitleDescSections/bkt.svg";

import "./elektronikusFizetes.scss";

const ElektronikusFizetes = (props) => {
  const {
    firstComponent,
    SEO,
    products,
  } = props.pageContext.content;

  const { paymentMethods, registerAndDownload, productCategories } =
    props.pageContext;

  const productCategoriesToCard = props.pageContext.content.productCategories;
  const location_href = props.location.pathname;
  return (
    <div className="elektronikusFizetes-wrapper">
      <LayoutHeaderFooter
        header_background_color={"#04BDB1"}
        header_secondary_color={"#18EECD"}
        SEO_title={SEO.title}
        SEO_description={SEO.description}
        SEO_keywords={SEO.keywords}
        SEO_image={SEO.image}
        SEO_location={location_href}
      >
        <FirstComponent
          primary_color={"#04BDB1"}
          secondary_color={"#18EECD"}
          content={{ ...firstComponent, products, productCategories }}
          image={firstComponent.image.url}
          image_mobile={firstComponent.image_mobile.url}
          subpage={true}
          subtitle={
            "<div><div>Tudtad?</div><p>2021. január 1-jétől minden online pénztárgép használatára kötelezett vállalkozásnak biztosítania kell a készpénzmentes fizetés lehetőségét a 2005. évi CLXIV. törvény értelmében. Ehhez mi, a CMO többféle, akár hibrid elektronikus fizetési megoldásokat is biztosítunk számodra.</p></div>"
          }
          big_image
          full_width
          onClick={() => {
            let item = document.getElementById("hagyomanyos-modszer");
            item.scrollIntoView({ behavior: "smooth", block: "start" });
          }}
          productCategoriesToCard={productCategoriesToCard}
        />
        {/* <HagyomanyosModszer
          title={"Válaszd a vadonatúj, innovatív megoldásainkat:"}
          id="hagyomanyos-modszer"
          subtitle={
            "Tudd le törvényesen az érintésmentes fizetési kötelezettségedet a mobilodról"
          }
          image={mobil_afr}
          item_title={"R QR kódos fizetés a mobilodon "}
          price={"990 Ft + áfa / hó"}
          footNote={
            "*A havidíj hűségidő nélkül, és havi fizetési periódusal értendő!"
          }
          description={
            "<ul><li>Többmillió felhasználót érhetsz el ezzel a fizetési megoldással</li><li>Azonnali jóváírás</li><li>Az applikáció visszajelzést küld a tranzakció sikerességéről</li><li>Önállóan is használható pénztárgép mellett</li><li>A CMO rendszer része</li><li>Nincs kártyahasználati díj</li><li>Számlakiállítás</li><li>Azonnali átutalásnak számít</li></ul>"
          }
          qr_code
          button_text={"Regisztrálj"}
          onClickUrl={"/qr-kodos-fizetes/r-qr-kodos-fizetes"}
          extra={
            "<div>A CMO QR technológiájával vendégeid a mobiltelefonodon is tudnak QR kód segítségével fizetni, miközben integrált számlázó appunk segit a NAV- os számlázásban. Regisztrálj, és segítünk, hogyan aktiválhatod ezt a modult.</div>"
          }
        /> */}
        <HagyomanyosModszer
          title={"Válaszd a vadonatúj, innovatív megoldásainkat:"}
          id="hagyomanyos-modszer"
          subtitle={
            "Tudd le törvényesen az érintésmentes fizetési kötelezettségedet a mobilodról"
          }
          image={softPOS_image}
          item_title={"softPOS bankkártya terminál a mobilodon"}
          price={"2.490 Ft*"}
          footNote={
            "*A havidíj 1 éves hűségidővel, és havi fizetési periódussal értendő!"
          }
          description={
            "<ul><li>Bankkártyaleolvasó terminálként használhatod a saját androidos NFC-s telefonod</li><li>Az applikáció visszajelzést küld a tranzakció sikerességéről</li><li>Bankfüggetlen</li><li>A CMO mobilapplikáció része</li><li>VISA és MASTERCARD elfogadás</li></ul>"
          }
          bankkartya
          button_text={"Áraink"}
          onClickUrl={"/softpos"}
          extra={
            "<div>A CMO softPOS technológiájával vendégeid a mobiltelefonodon is tudnak bankkártyával fizetni, miközben integrált számlázó appunk segit a NAV- os számlázásban. Regisztrálj, és segítünk, hogyan aktiválhatod ezt a modult</div>"
          }
        />
        <HagyomanyosModszer
          title={"Vagy ha a hagyományos módszerhez ragaszkodsz:"}
          subtitle={"Eszközfüggő bankkártya terminálok"}
          image={hagyomanyos}
          item_title={"Bankkártya terminál alapú elektronikus fizetés"}
          price={"akár 0 Ft"}
          description={
            "<ul><li>Érintésmentes</li><li>Hordozható</li><li>Minden bankkal kompatibilis</li><li>Havidíj vagy forgalom alapú elszámolás</li></ul><p>A kép csak illusztráció</p>"
          }
          qr_code
          bankkartya
          button_text={"Készülék és árak"}
          onClickUrl={"/bankkartya-terminal"}
          extra={
            '<div class="extra-content"><section>További funkciók amiket elérhetsz a CMO termináljaival:</section><section><ul><li>+ szépkártyás fizetés</li><li>+ hordozhatóság</li></ul></section></div>'
          }
        />
        <PaymentMethods
          title={
            "Fizetési lehetőségek, amivel a legtöbb CMO rendszer kompatibilis"
          }
          content={paymentMethods}
        />
        <ImageNextToDesc
          background_color={"#F7F7FA"}
          image={erintesmentes_image}
          title={"Mi az a QR kód alapú Azonnali&nbsp;Fizetési Rendszer (AFR)?"}
          description={
            "<div>Magyarországon 2020 májusa óta elérhető az azonnali fizetés, amellyel az elektronikus úton indított, 10 millió forint alatti belföldi átutalások az év minden napján, napszaktól függetlenül 5 másodpercen belül&nbsp;teljesülnek.</br></br>Ez lehetővé teszi, hogy vásárláskor a bankkártyás fizetés helyett egyéb elektronikus fizetési lehetőségeket, például <strong>QR-kód alapú fizetést&nbsp;válasszunk</strong>.</div>"
          }
        />
        <TitleIcons
          background_color={"#F7F7FA"}
          title={"Kinek ajánljuk a CMO azonnali fizetési rendszerét?"}
          content={[
            {
              image: vallalkozoknak,
              description:
                "Egyéni vállalkozóknak, akik csökkenteni szeretnék a havi kiadásaikat",
            },
            {
              image: keszpenzes,
              description:
                "Olyan vállalkozásoknak, ahol főleg a készpénzes tranzakció dominál",
            },
            {
              image: kutyuk,
              description:
                "Olyanoknak, akik nem szeretnének még egy kütyüre odafigyelni azoknak, akik szeretnék egy helyen kezelni az összes kereskedelmi adataikat",
            },
          ]}
        />
        <QuoteComponent background_color={"#363636"} />
        <HowToPay
          title={"Hogyan tud a vásárlód mobil applikációval fizetni?"}
          content={[
            {
              image: plusz,
              items: [
                {
                  icon: "1",
                  desc: "Nyisd meg telefonodon a CMO számlázó applikációt, és válaszd ki az “Új számla” menüpontot!",
                },
                {
                  icon: "2",
                  desc: "Töltsd ki a vásárlód adataid és a számlázandó tételeket! Válaszd az “Azonnali Fizetési” módot!",
                },
              ],
            },
            {
              image: qr,
              items: [
                {
                  icon: "3",
                  desc: "A megjelenő QR-kódot a vásárlód olvassa le a saját mobiltelefonjával, így megtekintheti a vásárlás részleteit. (A saját telefonodon ne zárd be az alkalmazást, mert itt fogod látni, hogy teljesült-e a vásárlás!)",
                },
              ],
            },
            {
              image: pipa,
              items: [
                {
                  icon: "4",
                  desc: "A vásárló a Fizetés gombra kattintva kiegyenlíti a számlát.",
                },
                {
                  icon: "5",
                  desc: "Sikeres vásárlás esetén pár másodpercen belül megjelenik a telefonodon a megerősítő üzenet.",
                },
              ],
            },
          ]}
        />
        <TitleDescription
          title={"Nézd meg videón is, milyen egyszerű a folyamat!"}
          description={
            '<iframe src="https://www.youtube.com/embed/O_OYg5UGNkQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
          }
          description_2={
            "Ugye, milyen egyszerű? Töltsd le most ingyenes számlázó applikációnkat, és 10 percen belül máris tudsz elektronikus fizetést fogadni! A legegyszerűbb beállításokban is segíteni fogunk."
          }
          button_text={"Letöltés"}
          onClick={() => {
            let item = document.getElementById("registerAndDownload");
            item.scrollIntoView({ behavior: "smooth", block: "start" });
          }}
        />
        <CardsArguments
          title={"7 nyomós érv a CMO QR-kód alapú azonnali fizetése mellett"}
          content={[
            {
              description:
                "Minden tranzakció azonnal megjelenik a CMO online számlázó programjában; így nem kell külön felvinned a bankkártyás vásárlásokat.",
            },
            {
              description: "Fix százalékos tranzakciós díj",
            },
            {
              description: "Nincs kártyahasználati díj",
            },
            {
              description:
                "Jóval olcsóbb egy bankkártya terminál használatánál.",
            },
            {
              description: "Nincsenek minimális tranzakciós limitek.",
            },
            {
              description: "Egyből a bankszámládra érkezik a jóváírás.",
            },
            {
              description:
                "Mivel ez a fizetési mód átutalásnak minősül, így nem a te költséged lesz az utalás tranzakciós díja, hanem a vásárló fogja fizetni azt!",
            },
          ]}
        />
        <TorvenyiEloiras
          title={
            "Így tudsz 10 perc alatt megfelelni a törvényi előírásnak az MNB QR kód szabványával!"
          }
          primary_color="#8C16FF"
          secondary_color="#5A20FC"
          items={[
            {
              desc: "Töltsd le és telepítsd mobiltelefonodra a CMO Számlázó mobilalkalmazást!",
            },
            {
              desc: "Ezután asztali gépeden vagy laptopodon lépj be a CMO felületére a telepítéskor megadott felhasználónévvel és jelszavaddal!",
            },
            {
              desc: 'Menj a "Licenszkezelés" menüpontra, és vásárold meg a licenszet az Elektronikus Fizettetési funkció aktiválásához.',
            },
            {
              desc: "Indítsd újra a mobilapplikációt, kattints a már aktív “Elektronikus fizettetés” menübe, majd állítsd be a kért adatokat.",
            },
          ]}
          description={
            "Az adatok beállítása után már meg is felelsz a törvényi előírásoknak!"
          }
          button_text={"Regisztráció weben"}
          hover="hover_white_purple"
        />
        <ImageNextToDesc
          image={ajanlas_image}
          color={"white"}
          title={"Ajánlj bennünket,"}
          subtitle={
            "és cserébe egy évig ingyen használhatod a CMO azonnali fizetési rendszerét!"
          }
          description={
            "<div>Hívd meg ismerőseid a mobilapplikáció Beállítások / Promóciók menüpontjából, és gyűjts 10 regisztált felhasználót!</br>Hálánk jeléül cserébe 12 hónapig ingyenesen használhatod az azonnali fizetés szolgáltatásunk, így spórolunk neked 19200 Ft + ÁFA-t!</div>"
          }
          primary_color={"#04BEB2"}
          secondary_color={"#0FD9C1"}
          button_text={"Regisztrálok a CMO online számlázó rendszerébe!"}
          onClick={() => {
            let item = document.getElementById("registerAndDownload");
            item.scrollIntoView({ behavior: "smooth", block: "start" });
          }}
          footNote={
            "Az online számlázó rendszer letöltése és használata ingyenes."
          }
        />
        <TitleDescSections
          title={"Kinek ajánljuk a bankkártya terminált?"}
          subtitle={
            "Amennyiben pénztárgéped összekapcsoltad már a CMO felhő alapú kereskedelmi rendszerével, még egyszerűbb dolgod van."
          }
          image={bkt}
          right
          description={`<ul>
              <li>Olyan vállalkozásoknak, ahol gyors fizetési megoldás szükséges</li>
              <li>Olyan vállalkozásoknak, ahol a vásárlók fel tudják használni SZÉP kártyájuk egyenlegét</li>
              <li>Olyan vállalkozásoknak, ahol nagy összegű pénzforgalom zajlik</li>
            </ul>`}
        />
        <TitleDescription
          title='"Bankkártya terminált,<br />
          QR Kód alapú Azonnali fizetési rendszert,<br />vagy SoftPOS-t válasszak?”'
          titleMaxWidth="1000px"
        />
        <FullWidthImageBox
          image={velemeny}
          content={{
            title: "Kérj véleményt szakértőnktől",
            description:
              "Az alábbi gombra kattintva átirányítunk egy űrlapra, amin keresztül ingyenesen igénybe veheted a CMO egy szakértőjét, aki segít neked kiválasztani az üzletedhez legmegfelelőbb bankkártyterminált.",
          }}
          color={"white"}
          button_text={"Kapcsolatfelvétel"}
          onClickUrl={"/form/erintesmentes-fizetes"}
          right
        />
        <RegisterAndDownload content={registerAndDownload} />
      </LayoutHeaderFooter>
    </div>
  );
};

export default ElektronikusFizetes;
