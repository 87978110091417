import React from "react";

import LayoutComponent from "../LayoutComponent/LayoutComponent";

import "./titleIcons.scss";

const TitleIcons = ({
  title,
  content,
  background_color,
  primary_color,
  secondary_color,
}) => {
  return (
    <div className="titleIcons-wrapper">
      <LayoutComponent
        background={
          primary_color && secondary_color
            ? `linear-gradient(30deg, ${primary_color} 30%, ${secondary_color} 55%, ${primary_color} 65%)`
            : background_color
        }
      >
        <div className="titleIcons-body-content">
          <h2 className="titleIcons-title">{title}</h2>
          <div className="titleIcons-content">
            {content.map((item, index) => {
              return (
                <div className="titleIcons-item" key={index}>
                  <img src={item.image} alt="kiknek_ajanljuk" loading="lazy" />
                  <p className="titleIcons-item-desc">{item.description}</p>
                </div>
              );
            })}
          </div>
        </div>
      </LayoutComponent>
    </div>
  );
};

export default TitleIcons;
