import React from "react";

import ReactMarkDown from "react-markdown";
import rehypeRaw from "rehype-raw";

import LayoutComponent from "../LayoutComponent/LayoutComponent";

import "./titleDescSections.scss";

const TitleDescSections = ({
  title,
  subtitle,
  image,
  description,
  background_color,
  primary_color,
  secondary_color,
  right,
  left,
}) => {
  return (
    <div className="titleDescSections-wrapper">
      <LayoutComponent
        background={
          primary_color && secondary_color
            ? `linear-gradient(30deg, ${primary_color} 30%, ${secondary_color} 55%, ${primary_color} 65%)`
            : background_color
        }
      >
        <div className="titleDescSections-body-content">
          <h2 className="titleDescSections-title">{title}</h2>
          <div className="titleDescSections-subtitle">{subtitle}</div>
          <div
            className="titleDescSections-sections"
            style={right ? { flexDirection: "row-reverse" } : null}
          >
            <section className="titleDescSections-section-image-wrapper">
              <img src={image} alt="QR kódos fizettetés online pénztárgéppel" />
            </section>
            <section className="titleDescSections-section-content-wrapper">
              <ReactMarkDown
                rehypePlugins={[rehypeRaw]}
                children={description}
              />
            </section>
          </div>
        </div>
      </LayoutComponent>
    </div>
  );
};

export default TitleDescSections;
