import React from 'react';

import LayoutComponent from '../LayoutComponent/LayoutComponent';

import './howToPay.scss';

const HowToPay = ({
  title,
  content,
  background_color,
  primary_color,
  secondary_color,
}) => {
  return (
    <div className="howToPay-wrapper">
      <LayoutComponent
        background={
          primary_color && secondary_color
            ? `linear-gradient(30deg, ${primary_color} 30%, ${secondary_color} 55%, ${primary_color} 65%)`
            : background_color
        }
      >
        <div className="howToPay-body-content">
          <h2 className="howToPay-title">{title}</h2>
          <div className="howToPay-content">
            {content.map((item, index) => {
              return (
                <div className={index % 2 !== 0 ? "howToPay-item reversed" : "howToPay-item"} key={index}>
                  <div className="howToPay-image-wrapper">
                    <img src={item.image} loading="lazy" />
                  </div>
                  <div className="howToPay-rows">
                    {item.items.map((row, i) => {
                      return (
                        <div
                          className={'howToPay-row'}
                          key={i}
                        >
                          <div className="howToPay-row-icon">{row.icon}</div>
                          <div className="howToPay-row-desc">{row.desc}</div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </LayoutComponent>
    </div>
  );
};

export default HowToPay;
