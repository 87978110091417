import React from "react";
import Button from "../Button/Button";
import LayoutComponent from "../LayoutComponent/LayoutComponent";
import "./torvenyiEloiras.scss";

const TorvenyiEloiras = ({
  title,
  items,
  description,
  background_color,
  primary_color,
  secondary_color,
  button_text,
  hover,
}) => {
  return (
    <div className="torvenyiEloiras-wrapper">
      <LayoutComponent
        background={
          primary_color && secondary_color
            ? `linear-gradient(30deg, ${primary_color} 30%, ${secondary_color} 55%, ${primary_color} 65%)`
            : background_color
        }
      >
        <div className="torvenyiEloiras-body-content">
          <h2 className="torvenyiEloiras-title">{title}</h2>
          <div className="torvenyiEloiras-items">
            {items.map((item, index) => {
              return (
                <div className="torvenyiEloiras-item" key={index}>
                  <p className="torvenyiEloiras-item-icon">{index + 1}</p>
                  <div className="torvenyiEloiras-item-desc">{item.desc}</div>
                </div>
              );
            })}
          </div>
          <div className="torvenyiEloiras-description">{description}</div>
          {button_text && (
            <a target="_blank" href={"https://app.cmo.hu/register"}>
              <Button
                variant={"outlined"}
                backgroundColor={"transparent"}
                color={"white"}
                text={button_text}
                hover={hover}
              />
            </a>
          )}
        </div>
      </LayoutComponent>
    </div>
  );
};

export default TorvenyiEloiras;
