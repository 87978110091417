import React from "react";
import ReactMarkDown from "react-markdown";
import qr from "../../assets/images/HagyomanyosModszer/qr.svg";
import bk from "../../assets/images/HagyomanyosModszer/bk.svg";
import Button from "../Button/Button";
import LayoutComponent from "../LayoutComponent/LayoutComponent";
import "./hagyomanyosModszer.scss";
import rehypeRaw from "rehype-raw";

const HagyomanyosModszer = ({
  id,
  title,
  subtitle,
  item_title,
  price,
  footNote,
  description,
  background_color,
  button_text,
  onClickUrl,
  image,
  qr_code,
  bankkartya,
  extra,
}) => {
  return (
    <div className="hagyomanyosModszer-wrapper" id={id}>
      <LayoutComponent background_color={background_color}>
        <div className="body-content">
          {title && <h2 className="hagyomanyosModszer-title">{title}</h2>}
          {subtitle && <p className="hagyomanyosModszer-subtitle">{subtitle}</p>}
          <div className="hagyomanyosModszer-content">
            <section>
              <p className="titl">{item_title}</p>
              <p className="price">{price}</p>
              <p className="footNote">{footNote}</p>
              <ReactMarkDown
                rehypePlugins={[rehypeRaw]}
                children={description}
              />
              <p className="payment-methods">Fizetési lehetőségek: </p>
              <div className="icons">
                {bankkartya && (
                  <div className="icon">
                    <img src={bk} alt="credit_card_payment method" loading="lazy"/>
                  </div>
                )}
                {qr_code && (
                  <div className="icon">
                    <img src={qr} alt="qr_code_payment method" loading="lazy"/>
                  </div>
                )}
              </div>
            </section>
            <section>
              <div className="image-wrapper">
                <img src={image} alt="hagyomanyos_modszer" loading="lazy"/>
              </div>
              <a href={onClickUrl}>
                <Button
                  variant="contained"
                  background_color={"#5107FF"}
                  text={button_text}
                  color={"white"}
                ></Button>
              </a>
            </section>
          </div>
          {extra && (
            <div className="extra">
              <ReactMarkDown
                rehypePlugins={[rehypeRaw]}
                children={extra}
              />
            </div>
          )}
        </div>
      </LayoutComponent>
    </div>
  );
};

export default HagyomanyosModszer;
