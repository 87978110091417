import React from "react";
import ReactMarkDown from "react-markdown";
import rehypeRaw from "rehype-raw";
import LayoutComponent from "../LayoutComponent/LayoutComponent";
import "./cardsArguments.scss";

const CardsArguments = ({
  title,
  content,
  background_color,
  primary_color,
  secondary_color,
}) => {
  return (
    <div className="cardsArguments-wrapper">
      <LayoutComponent
        background={
          primary_color && secondary_color
            ? `linear-gradient(30deg, ${primary_color} 30%, ${secondary_color} 55%, ${primary_color} 65%)`
            : background_color
        }
      >
        <div className="cardsArguments-body-content">
          <h2 className="cardsArguments-title">{title}</h2>
          <div className="cardsArguments-content">
            <div className="cardsArguments-row cardsArguments-first-row">
              {content.map((card, index) => {
                if (index < 3) {
                  return (
                    <div className="cardsArguments-card" key={index}>
                      <div className="cardsArguments-card-description">
                        <ReactMarkDown
                          rehypePlugins={[rehypeRaw]}
                          children={card.description}
                        />
                        <p className="cardsArguments-card-index">{index + 1}</p>
                      </div>
                    </div>
                  );
                } else {
                  return null;
                }
              })}
            </div>
            <div className="cardsArguments-row cardsArguments-second-row">
              {content.map((card, index) => {
                if (index >= 3) {
                  return (
                    <div className="cardsArguments-card" key={index}>
                      <div className="cardsArguments-card-description">
                        <ReactMarkDown
                          rehypePlugins={[rehypeRaw]}
                          children={card.description}
                        />
                        <div className="cardsArguments-card-index">{index + 1}</div>
                      </div>
                    </div>
                  );
                } else {
                  return null;
                }
              })}
            </div>
          </div>
        </div>
      </LayoutComponent>
    </div>
  );
};

export default CardsArguments;
